import React, { useEffect, useState } from 'react'; // , { useState } 
import { Avatar } from 'antd';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { InfoWraper, UserDropDwon } from './auth-info-style'; // NavAuth
// import Message from './message';
// import Notification from './notification';
// import Settings from './settings';
// import Support from './support';
import { Popover } from '../../popup/popup';
// import { Dropdown } from '../../dropdown/dropdown';
// import { logOut } from '../../../redux/authentication/actionCreator';
import Heading from '../../heading/heading';
import { getBaseURL } from '../../../Api/ApiConfig'

function AuthInfo() {
  const history = useHistory();
  const [name, setName] = useState();
  const [resName, setrResName] = useState();
  const [resLogo, setResLogo] = useState()
  // const dispatch = useDispatch();
  // const [state, setState] = useState({
  //   flag: 'english',
  // });

  // const { flag } = state;

  const SignOut = () => {
    window.localStorage.removeItem('steward_token');
    window.localStorage.removeItem('steward_expiration');
    history.push('/');
    window.location.reload()
  };

  useEffect(() => {
    const getStewardDetail = async () => {
      const isLoggedIn = localStorage.getItem('steward_token');
      const headers = {};
      headers.Authorization = `Bearer ${isLoggedIn}`;
      if (isLoggedIn !== null) {
        const stewardDetail = await axios.get(`${getBaseURL()}/smartPrism/captainDetail`, { headers })
        const response = await axios.get(`${getBaseURL()}/smartPrism/captainRetaurant`, { headers });
        setrResName(response.data.data.restaurantName);
        setResLogo(response.data.data.resLogo)
        setName(stewardDetail.data.data.resCaptainName)
      };
    }
    getStewardDetail();
  }, []);

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          <img src={resLogo} alt="" />
          <figcaption>
            <Heading as="h5">{name}</Heading>
            <p>{resName}</p>
          </figcaption>
        </figure>
        <ul className="user-dropdwon__links">
          <li>
            <Link to="#">
              <FeatherIcon icon="user" /> Profile
            </Link>
          </li>
          <li>
            <Link to="#">
              <FeatherIcon icon="settings" /> Settings
            </Link>
          </li>
          {/* <li>
            <Link to="#">
              <FeatherIcon icon="dollar-sign" /> Billing
            </Link>
          </li>
          <li>
            <Link to="#">
              <FeatherIcon icon="users" /> Activity
            </Link>
          </li> */}
          <li>
            <Link to="#">
              <FeatherIcon icon="bell" /> Help
            </Link>
          </li>
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <FeatherIcon icon="log-out" /> Sign Out
        </Link>
      </div>
    </UserDropDwon>
  );

  // const onFlagChangeHandle = value => {
  //   setState({
  //     ...state,
  //     flag: value,
  //   });
  // };

  // const country = (
  //   <NavAuth>
  //     <Link onClick={() => onFlagChangeHandle('english')} to="#">
  //       <img src={require('../../../static/img/flag/english.png')} alt="" />
  //       <span>English</span>
  //     </Link>
  //     <Link onClick={() => onFlagChangeHandle('germany')} to="#">
  //       <img src={require('../../../static/img/flag/germany.png')} alt="" />
  //       <span>Germany</span>
  //     </Link>
  //     <Link onClick={() => onFlagChangeHandle('spain')} to="#">
  //       <img src={require('../../../static/img/flag/spain.png')} alt="" />
  //       <span>Spain</span>
  //     </Link>
  //     <Link onClick={() => onFlagChangeHandle('turky')} to="#">
  //       <img src={require('../../../static/img/flag/turky.png')} alt="" />
  //       <span>Turky</span>
  //     </Link>
  //   </NavAuth>
  // );

  return (
    <InfoWraper>
      {/* <Message />
      <Notification />

      <Settings />
      <Support /> */}
      {/* <div className="nav-author">
        <Dropdown placement="bottomRight" content={country} trigger="click">
          <Link to="#" className="head-example">
            <img src={require(`../../../static/img/flag/${flag}.png`)} alt="" />
          </Link>
        </Dropdown>
      </div> */}

      <div className="nav-author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="head-example">
            <Avatar src="https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png" />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
}

export default AuthInfo;
