import React, { lazy } from 'react';
import { Switch, Route ,useRouteMatch} from 'react-router-dom';
// import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Dashboard = lazy(() => import('../../container/dashboard'));
const Tabled = lazy(() => import('../../container/dashboard/TList'));
const Orders = lazy(() => import('../../container/dashboard/OList'));
const CreateOrder=lazy(()=>import('../../container/dashboard/CreateOrder'))
const Payments = lazy(() => import('../../container/dashboard/PList'));
const OrderDetails=lazy(()=>import('../../container/dashboard/OrderDetail'));


function DashboardRoutes() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={Dashboard} />
      <Route path={`${path}/tables`} component={Tabled} />
      <Route path={`${path}/orders`} component={Orders} />
      <Route path={`${path}/new-orders`} component={CreateOrder} />
      <Route path={`${path}/payment`} component={Payments} />
      <Route path={`${path}/details`} component={OrderDetails} />
    </Switch>
  );
}

export default DashboardRoutes;
