import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader/root';
import { Provider, useSelector } from 'react-redux';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import store from './redux/store';
import Admin from './routes/admin';
import Auth from './routes/auth';
import './static/css/style.css';
import config from './config/config';
import ProtectedRoute from './components/utilities/protectedRoute';
import 'antd/dist/antd.less';
import { getBaseURL } from './Api/ApiConfig'

const { theme } = config;

function ProviderConfig() {
  const [resStatus, setResStatus] = useState();
  const { rtl, topMenu, darkMode } = useSelector((state) => {
    return {
      darkMode: state.ChangeLayoutMode.data,
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,

    };
  });

  const [path, setPath] = useState(window.location.pathname);
  const isLoggedIn = localStorage.getItem('steward_token');

  useEffect(() => {
    async function getStatus() {
      const headers = {};
      headers.Authorization = `Bearer ${isLoggedIn}`;

      if (isLoggedIn !== undefined && isLoggedIn !== null) {
        const response = await axios.get(`${getBaseURL()}/smartPrism/captainRetaurant`, { headers });
        if (response.data.statusCode === 200) {
          localStorage.setItem("steward_ResStatus", response.data.data.restaurantStatus)
          setResStatus(response.data.data.restaurantStatus)
          if (response.data.data.restaurantStatus === "INACTIVE") {
            toast.error("Your Restaurant has been InActive");
            window.localStorage.removeItem('steward_token');
            window.localStorage.removeItem('steward_expiration');
          }
        }
      }
    }
    getStatus();
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }
    // eslint-disable-next-line no-return-assign
    return () => (unmounted = true);

  }, [setPath]);

  return (
    <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
      <ThemeProvider theme={{ ...theme, rtl, topMenu, darkMode }}>
        <Router basename={process.env.PUBLIC_URL}>
          {!isLoggedIn ? <Route path="/" component={Auth} /> : <ProtectedRoute path="/steward" component={Admin} />}

          {isLoggedIn && (path === process.env.PUBLIC_URL || path === `${process.env.PUBLIC_URL}/`) &&
            resStatus === "ACTIVE" ? <Redirect to="/steward" /> : <Redirect to="/" />
          }
        </Router>
      </ThemeProvider>
    </ConfigProvider>
  );
}

function App() {
  return (
    <>
      <Provider store={store}>
        <ProviderConfig />
      </Provider>
      <ToastContainer
        autoClose={3000}
        position="top-right"
        theme="light"
        style={{ width: "300px" }}
      />
    </>
  );
}

export default hot(App);
